.NoFound{
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
}

.NoFound img{
    height: 60vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.NoFound h3{
    top: 50%;
    left: 8%;
    margin: auto;
    position: absolute;
    font-size: 30px;
    font-weight: 600px;
    font-family: 'Times New Roman', Times, serif;
}

.NoFound .homeIcon{
    background-color: black;
    color: white;
    border: 5px solid black;
}
@media only screen and (max-width: 970px) {
    .NoFound h3{
        position: static;
        margin-top: 100px;
        font-size: 20px;
    }

    .NoFound img{
        height: 40vh;
    }
  }